import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// defaultTheme
import theme from "./theme";

// routing
import Routes from "./routes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setBotSettingsData,
  setBotSettingsLoading,
} from "./redux/app/appSlice";
import SettingsLoader from "./SettingsLoader";
import Api from "./components/Api";
import { validateObjectData } from "./utils";
import config from "./config";
import { publicApiGET } from "./components/PrivateRoute";

const App = () => {
  const botSettings = localStorage.getItem("botSettings");
  const { isBotSettingLoading, botSettingsData } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // FETCH - Api config url
    publicApiGET(`./static/config.json`)
      .then((response) => {
        const { data, status } = response;
        const url = data?.API_SERVER_URL || config.DEFAULT_API_SERVER_URL;
        localStorage.setItem("apiServerURL", url);
      })
      .catch((error) => {
        //console.log("CONFIG json Error ----> ", error);
        localStorage.setItem("apiServerURL", config.DEFAULT_API_SERVER_URL);
      });

    // BOT Settings
    if (botSettings === "undefined" || botSettings == null) {
      console.log("isBotSettingLoading", isBotSettingLoading);
      dispatch(setBotSettingsData(config.defaultBotSettings));
      localStorage.setItem(
        "botSettings",
        JSON.stringify(config.defaultBotSettings)
      );
    } else {
      dispatch(setBotSettingsData(JSON.parse(botSettings)));
    }
  }, [isBotSettingLoading]);

  return (
    <>
      {botSettings === "undefined" || botSettings == null ? (
        <SettingsLoader />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme()}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export default App;
