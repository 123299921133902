export default {
  // Bot Settings
  botSettings: "/base/settings/app-settings/",

  // Auth endpoints
  login: "/api/users/login/",
  register: "/api/users/signup/",
  logout: "/api/users/logout/",
  profile: "/api/users/profile/",

  forgotPassword: "/base/v1/user/forgot-password/",
  resetPassword: "/base/v1/user/reset-password/",

  // Bot ilama query
  botQuery: "/api/api_server_query/",

  // Channel Info
  channelInfo: `/app/${process.env.REACT_APP_CLIENT_NAME}/ws/chat_channel_info/`,

  // Websocket URL
  ws: `${process.env.REACT_APP_SOCKET_PROTOCAL}://${process.env.REACT_APP_DOMAIN}/app/${process.env.REACT_APP_CLIENT_NAME}/ws/ongil_chat/`,
};
