import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";
import MinimalLayout from "../layouts/MinimalLayout";

// Auth Pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));
const QueryBotPage = Loadable(
  lazy(() => import("../views/QueryBot/query.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* Query Modes - Routes */}
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />
      <Route
        path="query-mode"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QueryBotPage />} />
      </Route>

      {/* Auth Routes */}
      <Route path="/login" element={<LoginPage />} />
      {/* <Route path="/signup" element={<RegisterPage />} /> */}
    </Routes>
  );
};

export default AppRoutes;
