import React, { useEffect } from "react";
import config from "../../config";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { privateApiGET } from "../../components/PrivateRoute";
import Api from "../../components/Api";
import {
  setActivePageID,
  disableInitialLoading,
  setProfileData,
} from "../../redux/app/appSlice";
import { filterMenuItemsByModes, MenuItems } from "../../menu-items";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: "85px 20px 110px 310px",
    transition: "padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    "&.noSidebar": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "20px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const MainLayout = ({}) => {
  const { isSidebarOpened, initialAppLoading } = useSelector(
    (state) => state.app
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Profile API comes here & initial loading and settting up pages happen here
  const handleFetchProfileData = () => {
    privateApiGET(Api.profile)
      .then((response) => {
        const { data, status } = response;
        console.log("Profile Data ---> ", data);
        if (status === 200) {
          dispatch(disableInitialLoading(false));
          dispatch(setProfileData(data?.data));
        } else {
          handleFetchProfileError();
        }
      })
      .catch((error) => {
        console.log("Profile API Error ----> ", error);
        handleFetchProfileError(error);
      });
  };

  // Handle Profile Error
  const handleFetchProfileError = (error) => {
    const { status } = error?.response || {};
    dispatch(disableInitialLoading(false));
    // localStorage.removeItem("botSettings");
    // localStorage.removeItem("token");
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    // const modes = localStorage.getItem("QueryModes");
    // const modesArr = filterMenuItemsByModes(JSON.parse(modes));
    // const { pathname } = location;
    // const firstModeUrl = modesArr.length ? modesArr[0].url : null;
    // const modeExists = modesArr.some((item) => item.url === pathname);

    // // User access unathurozied page
    // if (!modeExists) {
    //   console.log("Unauthorized Access!");
    //   const redirectUrl = firstModeUrl || config.defaultPath;
    //   navigate(redirectUrl);
    // }

    handleFetchProfileData();
  }, []);

  useEffect(() => {
    const tempArr = [];
    MenuItems.map((item) => {
      if (item.isChild) {
        tempArr.push(item);
        item.childList.map((subItem) => tempArr.push(subItem));
      } else {
        tempArr.push(item);
      }
    });

    const currMenuItem = tempArr.filter((obj) => obj.url === location.pathname);
    dispatch(setActivePageID(currMenuItem[0].id));
  }, [location]);

  const classes = useStyles();
  return (
    <>
      <Backdrop open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div className={classes.content}>
          <Navbar />
          <Sidebar />

          <div
            className={clsx({
              [classes.mainSection]: true,
              noSidebar: !isSidebarOpened,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
