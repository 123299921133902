import React, { useEffect, useState } from "react";
import { Drawer, Box, useTheme, useMediaQuery, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PerfectScrollbar from "react-perfect-scrollbar";

import { filterMenuItemsByModes, MenuItems } from "../../../menu-items";
import { useDispatch, useSelector } from "react-redux";
import { setSiderbarOpened } from "../../../redux/app/appSlice";

import FeatureList from "./FeatureList";
import { privateApiPOST } from "../../../components/PrivateRoute";
import Api from "../../../components/Api";
import { setQueryChannelInfo } from "../../../redux/query/querySlice";
import { botID } from "../../../constants";
import FeatureItem from "./FeatureItem";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey["800"],
    color: theme.palette.common.white,
    boxSizing: "border-box",
    borderRight: "none",
  },
  sidebarWrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
    "& ul": {
      listStyle: "none",
      margin: 0,
    },
    "& .MuiButtonBase-root": {
      fontFamily: theme.typography.fontFamily,
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      color: "#d3d3d3",
      letterSpacing: "0.4px",
    },
  },
  sidebarList: {
    padding: 0,
    "& >li": {
      paddingBottom: "3px",
      paddingTop: "3px",
      "& >.MuiButtonBase-root": {
        padding: "8px 15px",
        fontSize: "15px",
        lineHeight: "22px",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "#3a3a3a",
        },
      },
    },
  },
  listSelected: {
    backgroundColor: "#3a3a3a !important",
    color: "inherit !important",
  },
  chilItemSelected: {
    color: "#ffb442 !important",
  },
  childList: {
    padding: "0 0 5px",
    "& >li": {
      paddingTop: "1px",
      paddingBottom: "1px",
      position: "relative",
      "& >.MuiButtonBase-root": {
        padding: "6px 30px",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#a7a7a7",
        "&:hover": {
          color: "#e1e1e1",
        },
      },
    },
  },
  sidebarTitle: {
    fontWeight: 500,
    marginTop: "10px",
    letterSpacing: "0.5px",
    fontSize: "15px",
    lineHeight: "23px",
    color: "#9b9b9b",
  },
}));

const Sidebar = ({ window }) => {
  const { isSidebarOpened, profileData, activePageID } = useSelector(
    (state) => state.app
  );

  const dispatch = useDispatch();

  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () =>
    dispatch(setSiderbarOpened(!isSidebarOpened));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    dispatch(setSiderbarOpened(!matchDownMd));
  }, [matchDownMd]);

  const classes = useStyles();
  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        open={isSidebarOpened}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <PerfectScrollbar>
          <Box className={classes.sidebarWrapper}>
            <ul className={classes.sidebarList}>
              {MenuItems.map((item, index) => {
                return (
                  <li key={item.id}>
                    <FeatureItem key={index} itemObj={item} />
                  </li>
                );
              })}

              {/* {MenuItems.map((item) => (
                <li key={item.id}>
                  {item.isChild ? (
                    <>
                      <ButtonBase
                        onClick={() => {
                          dispatch(setActivePageID(item.id));
                          navigate(item.url);
                        }}
                        className={
                          item.id === activePageID ? classes.listSelected : ""
                        }
                      >
                        {item.title}
                      </ButtonBase>
                      <ul className={classes.childList}>
                        {item.childList.map((subItem) => {
                          return (
                            <li key={subItem.id}>
                              <ButtonBase
                                onClick={() => {
                                  dispatch(setActivePageID(subItem.id));
                                  navigate(subItem.url);
                                }}
                                className={
                                  subItem.id === activePageID
                                    ? classes.listSelected
                                    : ""
                                }
                              >
                                {subItem.title}
                              </ButtonBase>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <ButtonBase
                      onClick={() => {
                        dispatch(setActivePageID(item.id));
                        navigate(item.url);
                      }}
                      className={
                        item.id === activePageID ? classes.listSelected : ""
                      }
                    >
                      {item.title}
                    </ButtonBase>
                  )}
                </li>
              ))} */}
            </ul>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
