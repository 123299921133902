import React from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

import config from "../config";

export const useAuth = () => {
  return !!localStorage.getItem("token");
};

function PrivateRoute({ children }) {
  const auth = useAuth();
  return auth ? children : <Navigate to="/login" />;
}

export default PrivateRoute;

export const privateApiPOST = (url, data = {}) => {
  const token = localStorage.getItem("token");
  const server_url = localStorage.getItem("apiServerURL");
  //const endPointUrl = `${process.env.REACT_APP_API_BASEURL}${url}`;
  const endPointUrl = `${server_url}${url}`;

  return axios({
    method: "POST",
    url: endPointUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    data,
  });
};

export const privateApiGET = (url) => {
  const token = localStorage.getItem("token");
  const server_url = localStorage.getItem("apiServerURL");
  //const endPointUrl = `${process.env.REACT_APP_API_BASEURL}${url}`;
  const endPointUrl = `${server_url}${url}`;

  return axios({
    method: "GET",
    url: endPointUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const publicApiGET = (url) => {
  return axios({
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const appendApiServerURL = (url) => {
  const serverURL = localStorage.getItem("apiServerURL");
  const test = serverURL
    ? serverURL + url
    : config.DEFAULT_API_SERVER_URL + url;
  console.log("test", test);

  return serverURL ? serverURL + url : config.DEFAULT_API_SERVER_URL + url;
};
